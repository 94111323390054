<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.receive_requistion') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irri_pump_main.complain_id')"
              label-for="complain_id"
            >
              <b-form-input
                id="complain_id"
                v-model="search.complain_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irri_pump_main.subject')"
              label-for="subject"
            >
              <b-form-input
                id="irri_pump_main"
                v-model="search.subject"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.receive_requistion') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="complains" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(location)="data">
                      {{ getLocation(data.item) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.approval_status === 1" v-b-modal.modal-1 title="Receive Requisition" @click="receive(data.item)"><i class="ri-arrow-right-line"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_view" v-if="data.item.receiver_id !== null" v-b-modal.modal-1 :title="$t('irri_pump_main.view_requisition')" @click="view(data.item, $t('irri_pump_main.view_requisition'))"><i class="fas fa-eye"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="paginationDataLoad"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-1" size="xl" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <RequisitionDetails :id="id" :complain_requisition_id="complain_requisition_id" :key="id"/>
          </p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { complainRequisitionReceiveList, complainRequisitionReceive } from '../../api/routes'
import RequisitionDetails from './RequisitionDetails'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  components: {
    RequisitionDetails
  },
  data () {
    return {
      modalTitle: '',
      org_id: '',
      complainId: '',
      complainBy: '',
      complain_requisition_id: '',
      complains: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 0,
        complain_id: '',
        name: '',
        subject: '',
        office_id: this.$store.state.Auth.authUser.office_id
      },
      id: 0,
      requisition_id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      status: '',
      location: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.id === 0) ? this.$t('irrigation_config.scheme_type_entry') : this.$t('irrigation_config.scheme') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.IrriPumpInstall.requistion_applications
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('irri_pump_main.complain_id'), class: 'text-left' },
        { label: this.$t('irri_pump_main.location'), class: 'text-left' },
        { label: this.$t('pump_install.created_date'), class: 'text-center' },
        { label: this.$t('irri_pump_main.action'), class: 'text-center' }
      ]

      let keys = []

      keys = [
        { key: 'index' },
        { key: 'complain_id' },
        { key: 'location' },
        { key: 'updated_at' },
        { key: 'action' }
      ]

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    paginationDataLoad () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
    }
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, complainRequisitionReceiveList, params).then(response => {
        if (response.success) {
          this.complains = response.data.data
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async receive (item) {
      const receive = {
        complain_id: item.id,
        requisition_id: item.complain_requisition_id,
        receiver_id: this.$store.state.Auth.authUser.office_id
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      await RestApi.postData(irriSchemeServiceBaseUrl, complainRequisitionReceive, receive).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    getLocation (item) {
      const location = []
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDivision.text_bn !== undefined ? tmpDivision.text_bn : '')
      } else {
        location.push(tmpDivision.text_en !== undefined ? tmpDivision.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDistrict.text_bn !== undefined ? tmpDistrict.text_bn : '')
      } else {
        location.push(tmpDistrict.text_en !== undefined ? tmpDistrict.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUpazila.text_bn !== undefined ? tmpUpazila.text_bn : '')
      } else {
        location.push(tmpUpazila.text_en !== undefined ? tmpUpazila.text_en : '')
      }
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUnion.text_bn !== undefined ? tmpUnion.text_bn : '')
      } else {
        location.push(tmpUnion.text_en !== undefined ? tmpUnion.text_en : '')
      }
      return location.join(', ')
    },
    view (item, modalTitle) {
      this.id = item.id
      this.modalTitle = modalTitle
      this.org_id = item.org_id
      this.complainId = item.complain_id
      this.complain_requisition_id = item.complain_requisition_id
      this.farmer_id = item.farmer_id
      this.complainBy = (this.$i18n.locale === 'bn') ? item.name_bn : item.name
    }
  }
}
</script>
