<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(store)" @reset.prevent="reset" >
                    <ValidationProvider name="Receiver" vid="receiver_id" v-if="isShowOffice" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="receiver_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{ $t('pump_install.receiver') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              id="receiver_id"
                              v-model="forwardAllocate.receiver_id"
                              :options="officeLists"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Note" vid="note" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('pump_install.note')"
                        label-for="note"
                        slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="note"
                            v-model="forwardAllocate.note"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Note (Bn)" vid="note_bn">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('pump_install.note_bn')"
                        label-for="note_bn"
                        slot-scope="{ valid, errors }"
                        >
                          <b-form-textarea
                            id="note_bn"
                            v-model="forwardAllocate.note_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-2')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { complainRequisitionForwardAllocate } from '../../api/routes'
import DropdownMixin from '../../../../../mixins/dropdown'

export default {
  name: 'FormLayout',
  mixins: [DropdownMixin],
  props: ['forward_type', 'complain_id', 'requisition_id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      forwardAllocate: {
        complain_id: this.$props.complain_id,
        receiver_id: 0,
        sender_id: this.$store.state.Auth.authUser.office_id,
        requisition_id: this.$props.requisition_id,
        forward_type: this.$props.forward_type,
        note: '',
        note_bn: ''
      },
      isShowOffice: false,
      officeLists: []
    }
  },
  created () {
    this.childOfficeList()
    if (this.forwardAllocate.forward_type === 2) {
      this.isShowOffice = true
    } else {
      this.isShowOffice = false
    }
  },
  methods: {
    async store () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.$props.forward_type === 1) {
        this.forwardAllocate.receiver_id = this.getForwardReceiver(this.forwardAllocate.sender_id)
      }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, complainRequisitionForwardAllocate, this.forwardAllocate)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-2')
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    childOfficeList: function () {
      const officeList = this.$store.state.commonObj.officeList
      const tmpData = officeList.filter(officeItem => officeItem.parent_office_id === this.$store.state.Auth.authUser.office_id)
      this.officeLists = tmpData
    },
    getForwardReceiver (officeId) {
      const officeList = this.$store.state.commonObj.officeList
      const tmpOffice = officeList.find(office => office.value === officeId)
      return tmpOffice.parent_office_id
    }
  }
}
</script>
